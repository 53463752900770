
import './index.css'

import Logo from '../../components/Logo'
import Footer from '../../components/Footer'
import StoreLocation from '../../components/StoreLocation'
import TimeIcon from '../../assets/images/setimated-time-icon.png'
import CheckedIcon from '../../assets/images/checked.svg'


function Waiting(props) {
    const { waitingTime } = props.waitingInfo
    return (
        <div className='waiting-warpper'>
          <Logo></Logo>
          <div className='info-wrapper'>
            <span className='order-info-title'>Thanks for your order</span>
            {waitingTime == null || waitingTime > 0
                ? <WaitingInfo {...props.waitingInfo} />
                : <ReadyInfo />
            }
            <StoreLocation storeInfo={props.storeInfo}></StoreLocation>
          </div>
          <Footer></Footer>
        </div >
    );
}

function WaitingASAP({ waitingTime }) {
    return (
        <div className='estimated-time-wrapper'>
            <div className='estimated-time-top'>
                <img className='estimated-time-icon' src={TimeIcon} alt="time" />
                <span className='estimated-time-title'>Estimated Time</span>
            </div>
            <div className='estimated-time-info'>
                <span className='estimated-time-value'>{waitingTime}</span>
                <span className='estimated-time-unit'>mins</span>
            </div>
        </div>
    );
}

function WaitingScheduled({ scheduleInfo }) {
    const { startTime = {}, endTime = {} } = scheduleInfo.scheduledTimeInterval ?? {};
    return (
        <div className='estimated-time-wrapper'>
            <div className='estimated-time-top'>
                <img className='estimated-time-icon' src={TimeIcon} alt="time" />
                <span className='estimated-time-title'>Scheduled Time</span>
            </div>
            <div className='estimated-time-info'>
                <span className='estimated-time-value'>{`${(startTime.hour % 12) || 12}:${`${startTime.minute}`.padStart(2, '0')} - ${endTime.hour % 12}:${`${endTime.minute}`.padStart(2, '0')}`}</span>
                <span className='estimated-time-unit'>{startTime.hour < 12 ? 'am' : 'pm'}</span>
            </div>
        </div>
    );
}

function WaitingInfo({ waitingTime, scheduleInfo }) {
    return scheduleInfo?.scheduleType === 'SCHEDULED'
        ? <WaitingScheduled scheduleInfo={scheduleInfo} />
        : <WaitingASAP waitingTime={waitingTime} />
}

function ReadyInfo() {
    return (
        <div className='estimated-time-wrapper'>
            <div className='estimated-time-top'>
                <img className='estimated-time-icon' src={CheckedIcon} alt="time" />
                <span className='estimated-time-title'>Ready to pickup</span>
            </div>
        </div>
    );
}

export default Waiting